import { Directive, ElementRef, Renderer2, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appIf2025]'
})
export class If2025Directive {
  private targetDate = new Date('2025-01-01');

  constructor(templateRef: TemplateRef<any>, viewContainer: ViewContainerRef) {
    const currentDate = new Date();
    if (currentDate >= this.targetDate) {
      viewContainer.createEmbeddedView(templateRef);
    } else {
      viewContainer.clear();
    }
  }
}
